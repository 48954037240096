<template>
  <MDBModal tabindex="-1" size="xl" v-model="show" @show="GetDetail()" scrollable centered >
    <MDBModalHeader>
        <MDBModalTitle>綠界訂閱交易紀錄</MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      <MDBContainer>
        <MDBTable class="align-middle mb-0 bg-white" captionTop>
          <caption>
            <MDBRow>
              <MDBCol md="4">首次交易時間：{{ data?.process_date ?? '無' }}</MDBCol>
              <MDBCol md="2">每次交易金額：{{ data?.PeriodAmount ?? 0 }}</MDBCol>
              <MDBCol md="2">已交易次數：{{ data?.TotalSuccessTimes ?? 0 }}</MDBCol>
              <MDBCol md="2">已交易總金額：{{ data?.TotalSuccessAmount ?? 0 }}</MDBCol>
              <MDBCol md="2">訂單狀態：
                <MDBBadge v-if="data?.ExecStatus == 0" color="danger">訂閱中止</MDBBadge>
                <MDBBadge v-else-if="data?.ExecStatus == 1" color="info">訂閱進行中</MDBBadge>
                <MDBBadge v-else-if="data?.ExecStatus == 2" color="success">訂閱已完成</MDBBadge>
                <span v-else>無</span>
              </MDBCol>
            </MDBRow>
          </caption>
          <thead>
            <tr class="text-center">
              <th v-for="(text ,k) in headers" scope="col" :key="k">
                {{ text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="data == undefined">
              <tr class="text-center">
                <td colspan="4">
                  <transition name="fade">
                    <PageLoading></PageLoading>
                  </transition>
                </td>
              </tr>
            </template>
            <template v-if="data?.ExecLog?.length">
              <tr v-for="(row, k) in data.ExecLog" :key="k" class="text-center">
                <td scope="row">{{ row.process_date }}</td>
                <td scope="row">{{ row.TradeNo }}</td>
                <td scope="row">{{ row.amount }}</td>
                <td scope="row">
                  <MDBBadge v-if="row.RtnCode == 1" color="success">交易成功</MDBBadge>
                  <MDBBadge v-else color="danger">交易失敗</MDBBadge>
                </td>
              </tr>
            </template>
            <tr v-else class="text-center">
              <td colspan="4">無資料</td>
            </tr>
          </tbody>
        </MDBTable>
      </MDBContainer>
    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn @click="Close()">{{ $t("COMMON.CLOSE")}}</MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>
<style scope>
  .PageLoaging {
    position: relative;
  }
  .PageLoaging .Loading {
    position: fixed;
  }
</style>
<script>
import {
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBTable,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBadge,
} from 'mdb-vue-ui-kit';
import PageLoading from '@/components/Elements/PageLoading';
import { ref, computed } from "vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "ECPayDetail",
  components: {
    PageLoading,
    MDBContainer,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBTable,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBBadge,
  },
  props: {
    model: {
      type: Boolean,
      default: false
    },
    ecpay_id: {
      typeof: String,
      default: ""
    },
  },
  setup(props, { emit }) {
    const data = ref(undefined);
    const ecpayId = computed({
      get: () => props.ecpay_id,
      set: val => emit("update:ecpay_id", val)
    });
    const show = computed({
      get: () => props.model,
      set: val => emit("update:model", val)
    });

    const headers = ref([
      '交易時間',
      '交易編號',
      '交易金額',
      '交易狀態',
    ]);

    return {
      PageLoading,
      ecpayId,
      headers,
      data,
      show
    };
  },
  methods: {
    GetDetail() {
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/Subscription/ecpay_detail", this.ecpayId).then(response => {
            if (response.status == 200) {
              this.data = response.data?.data;
              this.ecpayId = "";
              resolve(response);
            }
          });
        })
      ]);
    },
    Close() {
      this.ecpayId = "";
      this.show = false;
      this.data = undefined;
    }
  },
};
</script>
